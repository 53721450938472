import { useEffect, useMemo, useState } from "react";

import { CartCode, ADD_TO_CART_SEARCH_PARAM, PROMO_CODE_SEARCH_PARAM } from "client/carts/types";
import {
  UTM_PARAMETERS_LIST,
  UTM_PARAM_TO_CAMPAIGN_JOURNEY_FIELD,
  CampaignJourneyCreate,
} from "client/marketing/types";
import { useAddCartPromoCodesMutation, useCartItemAddMutation } from "client/carts/hooks";
import { useCreateCampaignJourney } from "client/marketing/hooks";
import { useClientQuery } from "client/accounts/hooks";

import CartContext from "./CartContext";
import { useCartDrawer } from "../stackables";

type CartProviderProps = {
  children: React.ReactNode;
};

/** Sets up and manages query parameters and general cart state */
const CartProvider = ({ children }: CartProviderProps) => {
  const { mutate: applyPromoCodes } = useAddCartPromoCodesMutation();
  const { mutate: applyCampaignRebate } = useCreateCampaignJourney();
  const { mutate: addToCart, isSuccess: isCartLoadingSuccess } = useCartItemAddMutation();
  const [isCartPrebuilt, setIsCartPrebuilt] = useState(false);
  const { open: openCartDrawer } = useCartDrawer();
  const { data: clientData, isLoading: isClientDataLoading } = useClientQuery();

  // block is meant to check for a query param "cart-promo-code"
  // so we can apply it to the cart then remove the query param from the URL without redirecting
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const promoCodes =
      queryParameters
        .get(PROMO_CODE_SEARCH_PARAM)
        ?.split(",")
        .filter((code) => code) ?? [];
    if (promoCodes.length) {
      applyPromoCodes(promoCodes);
      queryParameters.delete(PROMO_CODE_SEARCH_PARAM);
      const newURL = queryParameters.toString()
        ? `${window.location.pathname}?${queryParameters.toString()}`
        : window.location.pathname;
      window.history.replaceState({}, document.title, newURL);
    }
  }, [applyPromoCodes]);

  // block is meant to check for utm parameters from marketing campaign links
  // so we can apply it to the cart
  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    const campaignJourney: CampaignJourneyCreate = {
      source: "",
      medium: "",
      campaign_slug: "",
      content: "",
      term: "",
    };
    UTM_PARAMETERS_LIST.forEach((key) => {
      const utmParamValue = queryParameters.get(key);
      if (utmParamValue) {
        const campaignJourneyKey = UTM_PARAM_TO_CAMPAIGN_JOURNEY_FIELD[key];
        campaignJourney[campaignJourneyKey] = utmParamValue;
      }
    });
    // only perform utm tracking if we have the required utm parameters to create a 'CampaignJourney'
    // in the backend
    const hasRequiredUTMParams = !!campaignJourney.campaign_slug && !!campaignJourney.medium;
    if (hasRequiredUTMParams) {
      applyCampaignRebate(campaignJourney);
    }
  }, [applyCampaignRebate]);

  // block is meant to check for a query param "add-prod"
  // so we can apply it to the cart then remove the query param from the URL without redirecting
  useEffect(() => {
    try {
      if (isClientDataLoading) {
        return;
      }
      const queryParameters = new URLSearchParams(window.location.search);
      const prodPatientSearchParams = queryParameters.getAll(ADD_TO_CART_SEARCH_PARAM);
      const productPatientPairs = prodPatientSearchParams
        .map((pair) => pair.split(","))
        .filter(([itemId, patientName]) => itemId || patientName);
      if (productPatientPairs.length) {
        productPatientPairs.forEach((pair) => {
          if (pair.length && pair.length <= 2) {
            setIsCartPrebuilt(true);
            const [itemId, patientName] = pair;
            // TODO: switch to handle patient ID instead of name once we expose a clients/patients external API
            // patient name is optional in the query param
            const patient = patientName
              ? clientData?.patients.find(
                  (p) => p.name.toLowerCase().trim() === patientName.toLowerCase().trim(),
                )
              : null;
            addToCart({
              cart_id: CartCode.ACTIVE,
              item: parseInt(itemId, 10),
              patient_id: clientData?.patients ? patient?.id : null,
            });
          }
        });

        // expecting to be linked to pages that don't req authentication s.t. users can view the cart via drawer
        openCartDrawer();

        // remove the query param from the URL without redirecting
        queryParameters.delete(ADD_TO_CART_SEARCH_PARAM);
        const newURL = queryParameters.toString()
          ? `${window.location.pathname}?${queryParameters.toString()}`
          : window.location.pathname;
        window.history.replaceState({}, document.title, newURL);
      }
    } catch {
      console.warn("Error parsing product-patient pairs from URL");
    }
  }, [addToCart, clientData?.patients, isClientDataLoading, openCartDrawer]);

  const contextValue = useMemo(() => {
    return {
      isCartPrebuilt,
      isCartMutationSuccess: isCartLoadingSuccess,
    };
  }, [isCartLoadingSuccess, isCartPrebuilt]);

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};

export default CartProvider;
