import { FullSortDirective, ListSearch, SortDirective } from "common/types";

import { WithStringValues } from "./types";

export const toFullSortDirective = <S extends string>(
  directive: SortDirective<S>,
): FullSortDirective<S> => (Array.isArray(directive) ? directive : [directive, "asc"]);

/** Compiles a sort directive array into a server "sort" string. Ascending is
 * assumed when no direction is provided. Particularly useful for keeping
 * in-line sorts expressions in lock step with declared types. */
export const compileSorts = <S extends string>(sortDirectives: SortDirective<S>[]) =>
  sortDirectives
    .map(toFullSortDirective)
    .filter(([field]) => field)
    .map(([field, direction]) => (direction === "asc" ? field : `-${field}`))
    .join(",");

/** Expands a server "sort" string into a an array of sort directive tuples.
 * Ascending is assumed when no direction is provided. Particularly useful for keeping
 * in-line sorts expressions in lock step with declared types. */
export const expandSorts = (sortStr: string): FullSortDirective<string>[] =>
  sortStr
    ? sortStr
        .split(",")
        .map(
          (term): SortDirective<string> =>
            term.charAt(0) === "-" ? [term.substring(1), "desc"] : term,
        )
        .map(toFullSortDirective)
        .filter(([field]) => field)
    : [];

/** Updates query params keeping any current values not set and making sure
 * that fields are kept consistent (eg. any changes to a param should also
 * reset the page to 1) */
export const updateListSearch = <T extends WithStringValues<Partial<ListSearch>>>(
  current: T,
  updates: Partial<T>,
): T => {
  // lets remove undefined update values for now, though we could consider using undefined,
  // or some config option later to be able to unset / replace all values
  const toApply = Object.fromEntries(Object.entries(updates).filter(([, v]) => v !== undefined));
  return { ...current, page: 1, ...toApply };
};
