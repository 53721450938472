import ReactDOM from "react-dom";
import React from "react";
import * as Sentry from "@sentry/react";
import { Severity } from "@sentry/react";

import { getSentryUserdata } from "./initialization";
import { Optional } from "./types";

const extractMessage = (error: Optional<string | Error>) =>
  error && (error instanceof Error ? error.message : error);

const demoteToWarningMessageChecks = [
  // We handle chunk load errors in the UI, there is no reason to alert on them.
  (m: string) => /^Loading chunk \d+ failed after \d+ retries./.test(m),
];

const initSentry = () => {
  if (!window.SENTRY_DSN) {
    console.warn("Sentry is not enabled because SENTRY_DSN is not set");
    return;
  }

  // We'll set the DNS / Release values
  Sentry.init({
    dsn: window.SENTRY_DSN,
    release: RELEASE_VERSION,
    attachStacktrace: true, // Attach stack traces to any message rather than just exceptions
    denyUrls: [
      // Prevent any GTM errors from being sent since we cannot take action on these
      /googletagmanager\.com\//i,
      // Prevent any local file errors from being sent if someone downloads the page
      /^file:\/\//i,
      // Prevent any Pixel errors from being sent since we cannot take action on these
      /cdn\.popt\.in\//i,
      // Webkit beef
      /@webkit-masked-url/i,
    ],
    ignoreErrors: [
      // filter out these GTM errors: https://vetcove.sentry.io/issues/4339138541/, https://vetcove.sentry.io/issues/4343020355/
      "Non-Error promise rejection captured with value: Object Not Found Matching Id",
      // filter out UnhandledRejection errors that have no information: https://vetcove.sentry.io/issues/3316237761
      "Non-Error promise rejection captured with value: undefined",
      // filter out ResizeObserver loop completed with undelivered notifications: https://vetcove.sentry.io/issues/3890839167/
      "ResizeObserver loop completed with undelivered notifications",
    ],

    beforeSend: (event, hint) => {
      const message = extractMessage(hint?.originalException);

      if (message && demoteToWarningMessageChecks.some((check) => check(message))) {
        return { ...event, level: Severity.Warning };
      }

      return event;
    },
  });

  // We'll attempt to get the user data out of the document
  const sentryUserData = getSentryUserdata();

  // If it exists, let's set the user
  if (sentryUserData) {
    Sentry.setUser(sentryUserData);
  }
};

const render = (App: React.ComponentType<any>) => {
  initSentry();
  const reactBody = document.getElementById("ReactBody");

  if (reactBody) {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      reactBody,
    );
  }
};

export default render;
